<template>
    <div>
        <div class="container">
            <div class="row" v-if="loading">
                <br>
                <div class="col-12 text-center">
                    <div class="spinner-border" role="status">
                    </div>
                </div>
            </div>
            <div class="row rtl" v-else>
                <div class="col-12">
                    <h3 class="mt-3">زانیاری بەکار‌هێنەر</h3>
                    <br>
                    <table class="report-table table table-striped">
                        <tbody>
                            <tr>
                                <th>ناوی بەکارهێنەر</th>
                                <th>{{customer.info.customer_name}}</th>
                            </tr>
                            <tr>
                                <th>ژمارەی تەلەفۆن</th>
                                <th>{{customer.info.customer_phone}}</th>
                            </tr>
                            <tr>
                                <th>ناونیشان</th>
                                <th>{{customer.info.city_name_ku}}-{{customer.info.town_name_ku}}-{{customer.info.place_name_ku}}-{{customer.info.customer_location}}
                                </th>
                            </tr>
                            <tr>
                                <th>ئاست</th>
                                <th>{{customer.info.customer_genration}}</th>
                            </tr>
                            <tr>
                                <th>ژمارەی پێوەلکاوی ڕاستەوخۆ</th>
                                <th>{{customer.info.direct_count}}</th>
                            </tr>
                            <tr>
                                <th>باڵانسی بەشداربوو لە نێتۆرک</th>
                                <th>{{$service.toIQD(customer.network.iqd)}}</th>
                            </tr>
                            <tr>
                                <th>باڵانسی بەکارهاتوو لە کڕینی کاڵا</th>
                                <th>{{$service.toIQD(customer.invoice.iqd)}}</th>
                            </tr>
                            <tr>
                                <th>باڵانسی دەرهاتوو لە نێتۆرک</th>
                                <th>{{$service.toIQD(customer.out.iqd)}}</th>
                            </tr>
                            <tr>
                                <th>باڵانسی ئێستا</th>
                                <th>{{$service.toIQD(customer.balance.iqd)}}</th>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                customer_id: 0,
                customer: {
                    info: {},
                    network: {},
                    invoice: {},
                    out: {},
                    balance: {}

                },
                loading: false,
            }
        },
        methods: {
            getData() {
                this.loading = true
                axios.post('report/userBalance.php', {
                        customer_id: this.customer_id
                    })
                    .then(r => {
                        this.customer = r.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
        mounted() {
            this.customer_id = this.$route.params.id
            this.getData()
        }
    }
</script>